import React from 'react';
import group from '../img/Team/Ekip.svg';
import Berkay from '../img/Team/Berkay.svg';
import Yiğit from '../img/Team/Yiğit.svg';
import Ece from '../img/Team/Ece.svg';
import İrem from '../img/Team/İrem.svg';

import group_mobile from '../img/Team/Ekip-Mobil.svg';

const Team = () => {
  return (
    <div className="mt-10 md:mt-24">
      {/* Mobile View */}
      <div className="md:hidden">
        <div className="flex justify-center mt-8">
          <img src={group_mobile} alt="Team" className="w-3/4" />
        </div>
        <div className="flex flex-col justify-center items-center mt-8 space-y-8">
          <MemberCard image={Berkay} name="Berkay Yalçınkaya" role="Yazılım ve Mobil Uygulama Geliştirici" />
          <MemberCard image={Yiğit} name="Niyazi Yiğit Kalın" role="Proje Yürütücüsü" />
          <MemberCard image={Ece} name="Ece Dobrıcalı" role="Pazarlama ve İletişim Uzmanı" />
          <MemberCard image={İrem} name="İremnur Çelebi" role="Grafik Tasarımcı" />
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:block">
        <div className="flex justify-center">
          <img src={group} alt="Team" className="w-3/4" />
        </div>
        <div className="flex justify-between mt-8 mx-4 md:mx-24">
          <MemberCard image={Berkay} name="Berkay Yalçınkaya" role="Yazılım ve Mobil Uygulama Geliştirici" />
          <MemberCard image={Yiğit} name="Niyazi Yiğit Kalın" role="Proje Yürütücüsü" />
          <MemberCard image={Ece} name="Ece Dobrıcalı" role="Pazarlama ve İletişim Uzmanı" />
          <MemberCard image={İrem} name="İremnur Çelebi" role="Grafik Tasarımcı" />
        </div>
      </div>
    </div>
  );
};

const MemberCard = ({ image, name, role }) => {
  return (
    <div className="text-center">
      <img src={image} alt={name} className="w-72 h-72 rounded-full mx-auto" />
      <p className="text-xl font-bold mt-4">{name}</p>
      <p className="text-lg mt-2">{role}</p>
    </div>
  );
};

export default Team;

import React, { useState } from 'react';
import Desktop_Lidar_1 from '../img/Menu/Lidar/Desktop/Lidar01.svg';
import Desktop_Lidar_2 from '../img/Menu/Lidar/Desktop/Lidar02.svg';
import Desktop_Lidar_3 from '../img/Menu/Lidar/Desktop/Lidar03.svg';
import Desktop_Lidar_4 from '../img/Menu/Lidar/Desktop/Lidar04.svg';
import Desktop_Lidar_5 from '../img/Menu/Lidar/Desktop/Lidar05.svg';
import Desktop_Lidar_6 from '../img/Menu/Lidar/Desktop/Lidar06.svg';

import Mobile_Lidar_1 from '../img/Menu/Lidar/Mobile/Lidar01.svg';
import Mobile_Lidar_2 from '../img/Menu/Lidar/Mobile/Lidar02.svg';
import Mobile_Lidar_3 from '../img/Menu/Lidar/Mobile/Lidar03.svg';
import Mobile_Lidar_4 from '../img/Menu/Lidar/Mobile/Lidar04.svg';
import Mobile_Lidar_5 from '../img/Menu/Lidar/Mobile/Lidar05.svg';
import Mobile_Lidar_6 from '../img/Menu/Lidar/Mobile/Lidar06.svg';

const Menu = () => {
  const desktopImages = [
    Desktop_Lidar_1,
    Desktop_Lidar_2,
    Desktop_Lidar_3,
    Desktop_Lidar_4,
    Desktop_Lidar_5,
    Desktop_Lidar_6,
  ];

  const mobileImages = [
    Mobile_Lidar_1,
    Mobile_Lidar_2,
    Mobile_Lidar_3,
    Mobile_Lidar_4,
    Mobile_Lidar_5,
    Mobile_Lidar_6,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % desktopImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + desktopImages.length) % desktopImages.length
    );
  };

  const MobilenextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % mobileImages.length);
  };

  const MobileprevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + mobileImages.length) % mobileImages.length
    );
  };

  return (
    <div className="w-full h-full relative">
      <img
        src={window.innerWidth > 768 ? desktopImages[currentImageIndex] : mobileImages[currentImageIndex]}
        alt={`Image ${currentImageIndex + 1}`}
        className="w-full h-full object-cover"
      />
      <button
        className="absolute top-1/2 -translate-y-1/2 p-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 left-2 md:left-12"
        onClick={window.innerWidth > 768 ? prevImage : MobileprevImage}
      >
        &lt;
      </button>
      <button
        className="absolute top-1/2 -translate-y-1/2 p-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 right-2 md:right-12"
        onClick={window.innerWidth > 768 ? nextImage : MobilenextImage}
      >
        &gt;
      </button>
    </div>
  );
};

export default Menu;

import React, { useState } from 'react';
import desktop_1_1 from '../img/Intro/i1/desktop1_1.svg';
import desktop_1_2 from '../img/Intro/i1/desktop1_2.svg';

import desktop_2_1 from '../img/Intro/i2/desktop2_1.svg';
import desktop_2_2 from '../img/Intro/i2/desktop2_2.svg';

import desktop_3_1 from '../img/Intro/i3/desktop3_1.svg';
import desktop_3_2 from '../img/Intro/i3/desktop3_2.svg';

import mobile_1 from '../img/Intro/i1/mobile1.svg';
import mobile_2 from '../img/Intro/i2/mobile2.svg';
import mobile_3 from '../img/Intro/i3/mobile3.svg';

const ImageHoverChange = ({ normalImage, hoverImage }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <img
      className={`w-full h-full transition-transform duration-400 transform ${
        isHovered ? 'scale-105' : 'scale-100'
      }`}
      src={isHovered ? hoverImage : normalImage}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      alt="Desktop Image"
    />
  );
};

const Intro = () => {
  return (
    <div>
      {/* Desktop Images */}
      <div className="w-full h-full flex mt-8 md:mt-36">
        <div className="w-full h-full p-12 md:p-24 hidden md:flex">
          <ImageHoverChange normalImage={desktop_1_2} hoverImage={desktop_1_1} />
        </div>
      </div>

      <div className="w-full h-full flex mt-8 md:mt-36">
        <div className="w-full h-full p-12 md:p-24 hidden md:flex">
          <ImageHoverChange normalImage={desktop_2_1} hoverImage={desktop_2_2} />
        </div>
      </div>

      <div className="w-full h-full flex mt-8 md:mt-36">
        <div className="w-full h-full p-12 md:p-24 hidden md:flex">
          <ImageHoverChange normalImage={desktop_3_1} hoverImage={desktop_3_2} />
        </div>
      </div>

      {/* Mobile Images */}
      <div className="sm:block md:hidden mt-16">
        <div>
          <img className="w-full h-full" src={mobile_1} alt="Mobile Image 1" />
        </div>
      </div>

      <div className="sm:block md:hidden mt-8">
        <div>
          <img className="w-full h-full" src={mobile_2} alt="Mobile Image 2" />
        </div>
      </div>

      <div className="sm:block md:hidden mt-8">
        <div>
          <img className="w-full h-full" src={mobile_3} alt="Mobile Image 3" />
        </div>
      </div>
    </div>
  );
};

export default Intro;

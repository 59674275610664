import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Intro from './components/Intro';

import Menu1 from './components/Menu1';
import Menu2 from './components/Menu2';
import Menu3 from './components/Menu3';
import Menu4 from './components/Menu4';
import Menu5 from './components/Menu5'

import Team from './components/Team';
import Footer from './components/footer';



function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Intro />
      <Menu1 />
      <Menu2 />
      <Menu3 />
      <Menu4 />
      <Menu5 />



      <Team />
      <Footer />
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { RiMenuLine } from "react-icons/ri";
import { GrClose } from 'react-icons/gr';
import NavbarLogo from '../archivise-navbarlogo.svg';
import Logo from '../archivise-logo.svg';



const Navbar = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 mx-auto md:px-24 px-10 text-black">
      <img src={NavbarLogo} />
      <div onClick={handleNav}>
        <RiMenuLine size={44} className="hidden text-[#082743] hover:text-[#FB8A2B]" />
      </div>

      <div
        className={
          !nav
            ? 'fixed right-0 top-0 w-full md:w-1/5 h-full bg-[#082743]'
            : 'fixed right-[-100%]'
        }
      >
        <div className="flex justify-between items-center pt-12 px-4 mx-auto ">
          <img src={Logo} className="m-4 text-white hover:text-[#FB8A2B]" />
          <div onClick={handleNav}>
            <GrClose
              size={24}
              className="m-4 text-white hover:text-[#FB8A2B]"
            />
          </div>
        </div>

        <ul className="pt-8  text-white p-4 text-center md:text-left">
          <li to = "https://www.archiviseapp.com"className="p-4 hover:text-[#FB8A2B] ">Hakkımızda</li>
          <li className="p-4 hover:text-[#FB8A2B]">Keşfet</li>
          <li className="p-4 hover:text-[#FB8A2B]">Sosyal</li>
          <li className="p-4 hover:text-[#FB8A2B]">İletişim</li>
          <div className="absolute inset-x-0 bottom-0 mb-16 p-4 items-center text-gray-500">
            <li className="p-4 hover:underline-offset-8">Politikalar</li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

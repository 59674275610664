import React from 'react';
import backgroundImage from '../img/hero/Hero-Content-Desktop.svg';
import mobilHero from '../img/hero/hero_mobile.svg';

const Hero = () => {
  return (
    <div>
      {/* Desktop View */}
      <div className="hidden sm:block">
        <div className="p-8 text-[#FB8A2B] font-bold text-center text-4xl">
          <p className="py-4">Mimari Tasarım</p>
          <p className="py-4">Sektöründe</p>
          <p className="py-4">Esnek Çözümler.</p>
        </div>
      </div>

      {/* Mobile View */}
      <div className="sm:hidden">
        <div className="p-8 text-[#FB8A2B] font-bold text-center text-4xl">
          <p className="py-4">Mimari Tasarım</p>
          <p className="py-4">Sektöründe</p>
          <p className="py-4">Esnek Çözümler.</p>
        </div>
      </div>

      {/* Desktop Image */}
      <div className="hidden sm:block">
        <img src={backgroundImage} alt="Background" className="w-full mx-auto" />
      </div>

      {/* Mobile Image */}
      <div className="sm:hidden">
        <img src={mobilHero} alt="Mobile Hero" className="w-full mx-auto" />
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';
import instagramLogo from '../img/Social/instagram-logo-1.svg';
import linkedInLogo from '../img/Social/LinkedIn-logo.svg';
import mailLogo from '../img/Social/X-logo.svg';
import archiviseLogo from '../img/Social/archivise-logo.svg';

const Footer = () => {
  const emailAddress = 'archivise.app@gmail.com';

  return (
    <footer className="bg-[#082743] text-white p-16 mt-48">
      {/* Mobile View */}
      <div className="md:hidden w-full h-full">
        <div className="mx-auto flex w-full h-full flex-col items-center">
          {/* Logo or title */}
          <div className="mb-16 flex justify-between w-full h-full">
            <img src={archiviseLogo} alt="Archivise Logo" className="h-12 w-12" />
            <p className='text-l'>Keşfet</p>
          </div>

          {/* Contact Email */}
          <div className="mb-2 md:mb-0 text-center">
            <p>Bize Ulaşın:</p>
            <a href={`mailto:${emailAddress}`} className="font-bold text-2xl hover:underline">
              {emailAddress}
            </a>
          </div>

          <div className="mt-16 md:mb-0 text-center">
            <p>
              Projelerinizi yönetmek, ilham almak ve güncellemelerimizden haberdar olmak için bizi takip edin. Sosyal medya
              hesaplarımızı ziyaret edin, görüş ve önerilerinizi bizimle paylaşın!
            </p>
          </div>

          {/* Social Media and Policies Links */}
          <div className="flex mt-12 items-center space-x-16">
            <a
              href="https://instagram.com/archiviseapp"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300"
              aria-label="Instagram"
            >
              <img src={instagramLogo} alt="Instagram" className="h-6 w-6" />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300"
              aria-label="LinkedIn"
            >
              <img src={linkedInLogo} alt="LinkedIn" className="h-6 w-6" />
            </a>
            <a href="https://X.com/archiviseapp" className="hover:text-gray-300" aria-label="Email">
              <img src={mailLogo} alt="Mail" className="h-6 w-6" />
            </a>
          </div>

          {/* Copy Right and Policy */}
          <div className="text-sm text-gray mt-12 md:mt-0 flex flex-col">
            <span>Tüm Hakları Saklıdır</span>
            <span>© 2024 Archivise</span>
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:flex">
        <div className="flex w-full h-full">
          <div>
            <img src={archiviseLogo} alt="Archivise Logo" />
          </div>

          <div className="mt-24 flex items-center w-full h-full flex-col">
            <p className="text-s">Bize Ulaşın:</p>
            <a href={`mailto:${emailAddress}`} className="font-bold text-2xl mt-8 hover:underline">
              {emailAddress}
            </a>
            <p className="mt-16 w-1/2 h-1/2 text-center">
              Projelerinizi yönetmek, ilham almak ve güncellemelerimizden haberdar olmak için bizi takip edin. Sosyal
              medya hesaplarımızı ziyaret edin, görüş ve önerilerinizi bizimle paylaşın!
            </p>

            <div>
              <div className="flex space-x-96 mt-16">
                <p className='text-l'>Keşfet</p>
                <div className="flex space-x-24">
                  <a
                    href="https://instagram.com/archiviseapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-gray-300"
                    aria-label="Instagram"
                  >
                    <img src={instagramLogo} alt="Instagram" className="h-6 w-6" />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-gray-300"
                    aria-label="LinkedIn"
                  >
                    <img src={linkedInLogo} alt="LinkedIn" className="h-6 w-6" />
                  </a>
                  <a href="https://X.com/archiviseapp" className="hover:text-gray-300" aria-label="Email">
                    <img src={mailLogo} alt="Mail" className="h-6 w-6" />
                  </a>
                </div>

                <div className="text-sm text-gray mt-12 md:mt-0 flex flex-col">
                  <span>Tüm Hakları Saklıdır</span>
                  <span>© 2024 Archivise</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
